/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import {
  ActionIcon,
  Badge,
  Button,
  Pagination,
  Select,
  Skeleton,
  Table,
  Text,
  TextInput,
  Anchor,
  Modal,
} from "@mantine/core";
import { BeatLoader } from "react-spinners";
import { DatePicker } from "@mantine/dates";
import { Eye, History, Search, Plus } from "tabler-icons-react";
import { formatISO } from "date-fns";
import { useDebouncedValue } from "@mantine/hooks";
import {
  deleteAgreementBulk,
  getAgreementsDocsLockerCsvDownload,
  getAgreementsInReviewCsvDownload,
} from "../../utilities/apis/agreements";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getDocLockerList,
  getAgreementTypesApi,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";
import UserAvatarView from "../../components/UserAvatarView";
import DashboardNotifications from "../DashboardNotifications";
import { agreementStatusColors, getStatusColor, getStatusText } from "../../utilities/enums";
import AgreementAnalytics from "../../components/AgreementAnalytics/AgreementAnalytics";
import colors, { themeColor } from "../../utilities/design";
import AgreementGraph from "./AgreementGraph";
import Styles from "./AgreementsListingPage.module.css";
import { INDIA_STATES_UNIONS } from "./AgreementForm2";
import { selectUserData } from "../../redux/selectors";
import { useSelector } from "react-redux";
import AgreementListModal from "./AgreementListModal";
import AgreementAuditLogModal from "./AgreementAuditLogModal";
import RelatedAgreementModal from "./agreementRelatedModal";
import { getBussinessUnit } from "../../utilities/apis/serviceProvider";

const statusOptions = [
  {
    label: "INTIATED",
    value: "CREATED",
  },
  {
    label: "UNDER REVIEW",
    value: "Review",
  },
  {
    label: "PENDING APPROVAL",
    value: "Approval",
  },
  {
    label: "FINALISED",
    value: "Signature",
  },
  {
    label: "PENDING SIGNATURE",
    value: "SENT",
  },
  {
    label: "PARTIALLY COMPLETED",
    value: "PARTIALLYCOMPLETED",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "DECLINED",
    value: "DECLINED",
  },
  {
    label: "SIGNED",
    value: "SIGNED",
  },
];
const estampOption = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const DocsLocker = () => {
  // let businessunitList = [];
  const [businessunitList, setbusinessunitList] = useState([]);

  useEffect(() => {
    const fetchBusinessUnits = async () => {
      try {
        const { data } = await getBussinessUnit();
        if (data) {
          setbusinessunitList(data.businessunitList || []);
        } else {
          console.error("Invalid business unit response format:", response);
        }
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };

    fetchBusinessUnits();
  }, []);
  // console.log(businessunitList);

  const [open, setOpen] = useState(false);
  const currentUser = useSelector(selectUserData) || {};
  // console.log("ass--", currentUser.loginAs.id);
  const [listing, setListing] = useState({
    list: null,
    agreementsCount: null,
    loaded: false,
    sortBy: "id",
    sortDirection: "desc",
    searchString: "",
    statusType: "",
    estamp: null,
    agreementType: null,
    location: null,
    startDate: null,
    endDate: null,
    partyName: "",
    businessunit: "",
  });
  const [agreementTypeOptions, setAgreementTypeOptions] = useState([]);
  const [uiConfigs, setUiConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    page: 1,
  });
  const [debouncedSearchInput] = useDebouncedValue(listing.searchString, 500);
  const [agreementPayload, setAgreementPayload] = useState({});
  const [exportButtonLoading, setExportButtonLoading] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleCheckboxChange = (id, isChecked) => {
    setSelectedIds((prevSelectedIds) => {
      if (isChecked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      }
    });
  };

  const deleteHandler = async () => {
    console.log("Deleting agreements with IDs:", selectedIds);
    const agreementIds = selectedIds;
    setDeleteLoading(true);
    const response = await apiWrapWithErrorWithData(
      deleteAgreementBulk({ agreementIds })
    );
    if (response?.success) {
      showNotification({
        color: "green",
        title: "Agreements",
        message: "Agreement deleted successfully.",
      });
      setOpen(false);
      navigate("/app");
    } else {
      showNotification({
        color: "red",
        title: "Agreements",
        message: "Unable to delete agreement",
      });
    }
    setDeleteLoading(false);
  };

  const [transformedData, settransformedData] = useState([]);

  useEffect(() => {
    if (businessunitList.length > 0) {
      settransformedData(
        businessunitList.map((unit) => ({
          value: unit.id + "",
          label: unit.name,
        }))
      );
    }
  }, [businessunitList]);

  const fetchAgreementsList = async ({ page }) => {
    let filterOptions = {};
    if (listing.startDate && listing.endDate) {
      filterOptions["createdAt"] = {
        gte: formatISO(listing.startDate),
        lte: formatISO(listing.endDate),
      };
    } else if (listing.startDate || listing.endDate) {
      showNotification({
        title: "Agreement notification",
        message: "please select start and end date",
        color: "red",
      });
      return;
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });
    const payload = {
      page: page ? page : uiConfigs.page,
      sortByOptions: {
        [listing.sortBy]: listing.sortDirection,
      },
    };
    if (
      listing.agreementType &&
      listing.statusType &&
      listing.estamp &&
      listing.businessunit
    ) {
      filterOptions = {
        ...filterOptions,
        typeOfAgreement: {
          equals: listing.agreementType,
        },
        status: {
          equals: listing.statusType,
        },
        agreementType: {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        },
        mapBusinessunit: {
          equals: listing.businessunit,
        },
      };
    } else if (
      listing.agreementType ||
      listing.statusType ||
      listing.estamp ||
      listing.businessunit
    ) {
      if (listing.agreementType) {
        filterOptions["typeOfAgreement"] = {
          equals: listing.agreementType,
        };
      }
      if (listing.statusType) {
        filterOptions["status"] = {
          equals: listing.statusType,
        };
      }
      if (listing.estamp) {
        filterOptions["agreementType"] = {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        };
      }
      if (listing.businessunit) {
        filterOptions["mapBusinessunit"] = {
          equals: listing.businessunit,
        };
      }
    }
    if (listing.location) {
      filterOptions["location"] = listing.location;
    }
    if (listing.partyName.length > 0) {
      filterOptions["OR"] = [
        { secondParty: { path: "$.name", string_contains: listing.partyName } },
        {
          secondParty: {
            path: "$.indi_name",
            string_contains: listing.partyName,
          },
        },
      ];
    }

    payload["filterOptions"] = filterOptions;

    setAgreementPayload(payload);
    const response = await apiWrapWithErrorWithData(
      getDocLockerList({ ...payload })
    );
    if (response?.success && response?.agreements) {
      setListing({
        ...listing,
        list: response?.agreements,
        agreementsCount: response.agreementsCount,
        loaded: true,
      });
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.NO_ACTIVE_REQUEST,
    });
  };
  const data = listing?.list;

  useEffect(() => {
    getAgreementTypes();
  }, []);

  useEffect(() => {
    if (listing.agreementType || listing.statusType || listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.startDate && listing.endDate) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else {
      if (listing.startDate === null && listing.endDate === null) {
        fetchAgreementsList({});
      }
    }
  }, [listing.sortBy, listing.sortDirection]);

  useEffect(() => {
    fetchAgreementsList({});
  }, [uiConfigs.page]);

  const navigate = useNavigate();
  const onViewClick = (agreementId) => {
    // navigate(`/app/agreements/details/${agreementId}`);
    const url =
      process.env.PUBLIC_URL +
      `/app/agreements_manager/agreements/details/${agreementId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getAgreementTypes = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
    if (resp?.success && resp?.typesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.typesOfAgreement]));
      const temp = uniqueValues.map((item) => ({ label: item, value: item }));
      setAgreementTypeOptions([...agreementTypeOptions, ...temp]);
    }
  };
  const handleCsvDownload = async () => {
    try {
      setExportButtonLoading(true);
      const response = await apiWrapWithErrorWithData(
        getAgreementsDocsLockerCsvDownload({ ...agreementPayload })
      );
      if (response.success) {
        if (response?.agreements.length > 0) {
          const columnHeaders = Object.keys(response.agreements[0]);
          csvDownload({
            data: response.agreements,
            headers: columnHeaders,
            name: "agreement",
          });
        } else {
          showNotification({
            title: "Agreement csv download",
            message: "No agreement available",
            color: "red",
          });
        }
      }
      setExportButtonLoading(false);
    } catch (error) {
      setExportButtonLoading(false);
      showNotification({
        title: "Agreement csv download",
        message: "Could not load agreement csv",
        color: "red",
      });
    }
  };

  const clearHandler = async () => {
    try {
      setUiConfigs({
        ...uiConfigs,
        page: 1,
        loading: loadingStates.LOADING,
      });

      const payload = {
        page: 1,
        sortByOptions: {
          ["createdAt"]: listing.sortDirection,
        },
        filterOptions: {},
      };

      setAgreementPayload(payload);
      const response = await apiWrapWithErrorWithData(
        getDocLockerList({ ...payload })
      );

      if (response?.success && response?.agreements) {
        setListing({
          ...listing,
          searchString: "",
          agreementType: "",
          statusType: null,
          estamp: "",
          location: null,
          startDate: null,
          endDate: null,
          list: response?.agreements,
          agreementsCount: response.agreementsCount,
          loaded: true,
          estamp: null,
          partyName: "",
          businessunit: "",
        });
      }

      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    } catch (error) {
      console.log("Agreement list fetching error: ", error);
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [agreementId, setAgreementId] = useState(null);

  const handleSearchClick = (id) => {
    // Set the showModal state to true when the button is clicked
    setShowModal(true);
    // Pass the row id to the AgreementAuditLogModal component
    setAgreementId(id);
  };

  console.log(data);

  const handleCloseModal = () => {
    // Set the showModal state to false when the modal is closed
    setShowModal(false);
  };
  const [showModalRelated, setShowModalRelated] = useState(false);
  const [relatedAgreementId, setRelatedAgreementId] = useState(null);
  const handlePlusClick = (id) => {
    console.log("id: ", id);
    setShowModalRelated(true);
    setRelatedAgreementId(id);
  };
  const handleCloseRelatedAgreementModal = () => {
    setShowModalRelated(false);
  };
  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between my-4">
          <Text>Docs Locker</Text>
        </div>
      </div>

      {showModalRelated && (
        <Modal
          overflow="inside"
          opened
          onClose={handleCloseRelatedAgreementModal} // Call the handler on modal close
          size="calc(80vw)"
        >
          <RelatedAgreementModal agreementId={relatedAgreementId} />
        </Modal>
      )}
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row-sm">
            {/* <Text size="xs" className="mb-2" color="gray">
              Case sensitive search
            </Text> */}
            <div className="flex items-center">
              {/* <TextInput
                value={listing.searchString}
                onChange={(input) => {
                  const val = getValueForInput(input);
                  setListing((prevState) => ({
                    ...prevState,
                    searchString: val,
                  }));
                }}
                placeholder="Type to search..."
                icon={<Search size={14} />}
              /> */}
              <Select
                className="mx-2"
                placeholder="Select agreement status"
                value={listing.statusType}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    statusType: val,
                  }));
                }}
                data={statusOptions}
                style={{ minWidth: "228px" }}
              />
              <Select
                className="mx-2"
                placeholder="Estamp"
                value={listing.estamp}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    estamp: val,
                  }));
                }}
                data={estampOption}
                style={{ minWidth: "228px" }}
              />
              <Select
                className="mx-2"
                placeholder="Select agreement type"
                value={listing.agreementType}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    agreementType: val,
                  }));
                }}
                data={agreementTypeOptions}
                style={{ minWidth: "228px" }}
              />
              <Select
                className="mx-2"
                placeholder="Select Business Unit"
                value={listing.businessunit}
                data={transformedData}
                onChange={(value) => {
                  setListing((prevState) => ({
                    ...prevState,
                    businessunit: value,
                  }));
                }}
                style={{ minWidth: "228px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="flex items-center">
              {/* <Select
                className="mx-2"
                placeholder="Select location"
                searchable={true}
                value={listing.location}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    location: val,
                  }));
                }}
                data={INDIA_STATES_UNIONS}
                style={{ minWidth: "228px" }}
              /> */}
              <DatePicker
                className="mx-2"
                value={listing.startDate}
                onChange={(val) =>
                  setListing((preState) => ({ ...preState, startDate: val }))
                }
                placeholder="Created date (From)"
                minDate={
                  new Date(new Date().setMonth(new Date().getMonth() - 3))
                }
                maw={400}
                mx="auto"
                style={{ minWidth: "228px" }}
              />
              <DatePicker
                className="mx-2"
                value={listing.endDate}
                onChange={(val) =>
                  setListing((preState) => ({ ...preState, endDate: val }))
                }
                placeholder="Created date (To)"
                minDate={listing.startDate}
                disabled={listing.startDate ? false : true}
                style={{ minWidth: "228px" }}
              />
              <TextInput
                className="mx-2"
                value={listing.partyName}
                onChange={(input) => {
                  const val = getValueForInput(input);
                  setListing((prevState) => ({
                    ...prevState,
                    partyName: val,
                  }));
                }}
                placeholder="Party Name"
                type="text"
                style={{ minWidth: "228px" }}
              />

              <br />
            </div>
          </div>
        </div>
      </div>
      <br />
      <Button
        className="mx-2"
        color="green"
        onClick={() => fetchAgreementsList({ page: 1 })}
      >
        Search
      </Button>
      <Button className="mx-2" color="red" onClick={clearHandler}>
        Clear
      </Button>

      <Button
        className="mx-2 btn btn-success btn-lg float-right"
        disabled={exportButtonLoading}
        onClick={handleCsvDownload}
        color={themeColor(colors.agreement)}
      >
        Export
      </Button>
      <Modal
        overflow="inside"
        closeOnClickOutside={false}
        size="calc(30vw)"
        opened={open}
        onClose={() => setOpen(!open)}
        title="Delete Agreement"
      ></Modal>
      {showModal && (
        <Modal
          overflow="inside"
          opened
          onClose={handleCloseModal} // Call the handler on modal close
          size="calc(80vw)"
        >
          <AgreementAuditLogModal agreementId={agreementId} />
        </Modal>
      )}

      {currentUser.loginAs.id === 1 && (
        <Button
          disabled={deleteLoading || selectedIds.length === 0}
          className="ml-2"
          style={{
            backgroundColor: deleteLoading ? "#ffcccc" : "#ff4d4d",
            // borderRadius: "0.5rem",
            color: "#F5F5F5",
            // width: '180px',
          }}
          onClick={deleteHandler}
        >
          {deleteLoading ? (
            <BeatLoader size={10} color={colors.rejected} />
          ) : (
            "Delete"
          )}
        </Button>
      )}
      <br />
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {uiConfigs.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          {Array.from({ length: 10 }).map((i) => (
            <Skeleton key={i} height={30} radius="md" className="my-1 w-full" />
          ))}
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {listing.loaded &&
        uiConfigs.loading !== loadingStates.LOADING &&
        Boolean(data?.length) && (
          <div
            className="flex flex-col"
            style={{ overflowX: "scroll", scrollbarWidth: "none" }}
          >
            <Table striped>
              <thead>
              <tr>
                  <th>select</th>
                  <th>AIN</th>
                  <th>type</th>
                  <th>businessunit</th>
                  <th>status</th>
                  {/* <th>Created By</th> */}

                  {/* <th>First Party</th> */}
                  <th>second party</th>
                  <th>created date</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(
                    (v) =>
                      v.agreementData.json &&
                      v.agreementData.json.typeOfAgreement
                  )
                  .map((row) => {
                    
                    return(
                    <tr key={row.id}>
                      <td>
                        <input
                          type="checkbox"
                          id={`select-${row.id}`}
                          onChange={(e) =>
                            handleCheckboxChange(row.id, e.target.checked)
                          }
                        />
                      </td>
                      <td>{`Agreement-${row.id}`}</td>
                      <td>{row.agreementData.json.typeOfAgreement}</td>
                      <td>
                        {row.agreementData?.json?.location
                          ? row.agreementData?.json?.location
                          : row.agreementData?.json?.location}
                      </td>
                      {/* <td>
                        <UserAvatarView {...row.createdBy} />
                      </td> */}
                      <td>
                        <Badge color={getStatusColor(row.status)}>{getStatusText(row.status)}</Badge>
                      </td>
                      {/* <td>
                        {row.agreementData?.json?.firstParty?.name || "-"}
                      </td> */}
                      <td>
                        {row.agreementData?.json?.secondParty?.name ||
                          row.agreementData?.json?.secondParty?.reg_name ||
                          "-"}
                      </td>
                      <td>{formatDate(row.createdAt)}</td>
                      <td style={{ textAlign: "center" }}>
                        {row.relationshipType !== "Parent" ? (
                          <div className="flex flex-row ">
                            <ActionIcon
                              onClick={() => {
                                handlePlusClick(row.relationshipWith);
                              }}
                              color="white"
                            >
                              <Plus size={24} />
                            </ActionIcon>
                            {/* )} */}
                          </div>
                        ) : (
                          <span className="mr-2">{"--"}</span>
                        )}
                      </td>
                      <td>
                        <div className="flex flex-row mr-4">
                          <ActionIcon
                            onClick={() => {
                              onViewClick(row.id);
                            }}
                            color="white"
                          >
                            <Eye size={24} />
                          </ActionIcon>
                          {/* )} */}
                        </div>
                      </td>
                      <td>
                        <div className="flex flex-row mr-4">
                          {!row.agreementData?.json?.isLegacy ? (
                            <ActionIcon
                              onClick={() => handleSearchClick(row.id)}
                              color="white"
                            >
                              <History size={24} />
                            </ActionIcon>
                          ) : (
                            <>{"NA"}</>
                          )}

                          {/* )} */}
                        </div>
                      </td>
                    </tr>)
                  })}
              </tbody>
            </Table>
          </div>
        )}
      {listing.loaded &&
      uiConfigs.loading !== loadingStates.LOADING &&
      !data?.length ? (
        <div
          className="flex justify-center items-center"
          style={{
            minHeight: "300px",
          }}
        >
          No agreements
        </div>
      ) : null}
      {/* </div> */}
      {listing.agreementsCount ? (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={uiConfigs.page}
            onChange={(page) => {
              setUiConfigs({
                ...uiConfigs,
                page,
              });
            }}
            total={Math.ceil(listing.agreementsCount / 10)}
          />
        </div>
      ) : null}
      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        size="calc(30vw)"
        style={{ marginTop: "12%" }}
        title="Select Agreement Type"
      >
        <div className={Styles["popup-desig"]}>
          <p
            style={{
              cursor: "pointer",
              border: "2px solid lightblue",
              padding: "8px",
              margin: "0",
              marginBottom: "8px",
            }}
            onClick={() => navigate("/app/agreements_manager/agreements/new")}
          >
            Individual Agreement
          </p>
          <p
            style={{
              cursor: "pointer",
              border: "2px solid lightblue",
              padding: "8px",
              margin: "0",
            }}
            onClick={() =>
              navigate("/app/agreements_manager/agreements/new-bulk")
            }
          >
            Bulk Agreement
          </p>
          <div style={{ textAlign: "center", paddingTop: "3%" }}>
            <button
              style={{
                cursor: "pointer",
                background: "red",
                border: "none",
                padding: "8px",
                color: "white",
                margin: "5px 0",
                borderRadius: "10%",
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DocsLocker;
