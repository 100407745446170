import axios from "axios";
import { apiWrapWithErrorWithData, headersProvider } from "../apiHelpers";
import constants from "../constants";

export const createAgreement = ({ agreementData, ...restArgs }) =>
  axios.post(
    `${constants.utilityHost}/agreements/new-agreement`,
    {
      agreementData,
      ...restArgs,
    },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementWorkflow = () =>
  axios.get(`${constants.utilityHost}/agreement/workflow`, {
    headers: headersProvider(),
  });

export const getAgreementsList = ({ page, sortByOptions, filterOptions }) =>
  axios.post(
    `${constants.utilityHost}/agreements/list`,
    {
      page,
      filterOptions,
      sortByOptions,
    },
    {
      headers: headersProvider(),
    }
  );

export const getAllAgreementsList = ({ page, sortByOptions, filterOptions }) =>
  axios.post(
    `${constants.utilityHost}/allagreements/list`,
    {
      page,
      filterOptions,
      sortByOptions,
    },
    {
      headers: headersProvider(),
    }
  );
export const getAgreementInRenewalsList = ({
  page,
  sortByOptions,
  filterOptions,
  tabName,
}) =>
  axios.post(
    `${constants.utilityHost}/agreements/Renewal-List`,
    {
      page,
      filterOptions,
      sortByOptions,
      tabName,
    },
    {
      headers: headersProvider(),
    }
  );

export const getDocLockerList = ({ page, sortByOptions, filterOptions }) =>
  axios.post(
    `${constants.utilityHost}/agreements/DocLockerList`,
    {
      page,
      filterOptions,
      sortByOptions,
    },
    {
      headers: headersProvider(),
    }
  );
export const agreementTranSaveApi = (agreementTran) =>
  axios.post(
    `${constants.utilityHost}/agreement/tran`,
    {
      ...agreementTran,
    },
    {
      headers: headersProvider(),
    }

    // console.log(resp)
  );

export const updateAgreement = ({
  agreementId,
  agreementData,
  noOfCompletionDays,
  ...restArgs
}) =>
  axios.put(
    `${constants.utilityHost}/agreements/update/${agreementId}`,
    {
      agreementData,
      ...restArgs,
      noOfCompletionDays,
    },
    {
      headers: headersProvider(),
    }
  );

export const businessUnitDetails = ({ businessUnitId }) =>
  axios.get(`${constants.utilityHost}/businessunitdetails/${businessUnitId}`, {
    headers: headersProvider(),
  });

export const orgDetailsUpdate = ({ orgDetails, orgName }) =>
  axios.put(
    `${constants.utilityHost}/updateOrg`,
    { orgName, orgDetails },
    {
      headers: headersProvider(),
    }
  );

export const getOrgDetails = () =>
  axios.get(`${constants.utilityHost}/orgDetails`, {
    headers: headersProvider(),
  });

export const saveCafData = ({ agreementId, cafData }) =>
  axios.put(
    `${constants.utilityHost}/agreements/updatecaf/${agreementId}`,
    { cafData },
    {
      headers: headersProvider(),
    }
  );

export const sendAgainToReview = ({ agreementId }) =>
  axios.put(
    `${constants.utilityHost}/agreements/reviewagain`,
    { agreementId },
    { headers: headersProvider() }
  );

export const uploadFromLocal = ({ agreementId, formData }) =>
  axios.post(
    `${constants.utilityHost}/agreement/upload-from-local/${agreementId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
  );

export const getAgreementDetails = ({ agreementId }) =>
  axios.get(`${constants.utilityHost}/agreements/details/${agreementId}`, {
    headers: headersProvider(),
  });

export const getDocsToken = () =>
  axios.get(`${constants.utilityHost}/docs/fetchToken`, {
    headers: headersProvider(),
  });

export const getUserByRole = ({ roleId }) =>
  axios.get(`${constants.utilityHost}/service-providers/user-list/${roleId}`, {
    headers: headersProvider(),
  });

export const sendReminderId = ({ signatureId }) =>
  axios.get(
    `${constants.utilityHost}/agreements/details/sendReminder/${signatureId}`,
    {
      headers: headersProvider(),
    }
  );
export const putflowJson = (id, flowJsonData) =>
  axios.put(
    `${constants.utilityHost}/agreement/flowJsonUpdate/${id}`,
    {
      flowJsonData,
    },
    {
      headers: headersProvider(),
    }
  );
export const putLegacyFlowJson = (id, flowJsonData) =>
  axios.put(
    `${constants.utilityHost}/agreement/legacyFlowJsonUpdate/${id}`,
    {
      flowJsonData,
    },
    {
      headers: headersProvider(),
    }
  );

// export const deleteAgreementDetails = ({
//   agreementId,
// }) => axios.put(`${constants.utilityHost}/agreements/delete`, {
//   agreementId,
// }, {
//   headers: headersProvider(),
// });

export const deleteAgreementBulk = ({ agreementIds }) =>
  axios.put(
    `${constants.utilityHost}/agreements/delete`,
    {
      agreementIds,
    },
    {
      headers: headersProvider(),
    }
  );

export const createSigningRequest = (emails) =>
  axios.post(
    "https://w6bduqd0tj.execute-api.us-east-1.amazonaws.com/default/signer-proxy",
    {
      emails,
    },
    {
      noTrailingSlash: true,
    }
  );

const b64toUrl = async (base64Data) => {
  const r = await fetch(base64Data);
  const blob = await r.blob();
  return URL.createObjectURL(blob);
};
export const getGoogleDocsPreviewUrl = async (agreementId) => {
  let url = null;
  const data = await apiWrapWithErrorWithData(
    axios.get(
      `${constants.utilityHost}/docs/fetchDocsContentBase64/${agreementId}`,
      {
        headers: headersProvider(),
      }
    )
  );
  if (data.success && data.fileString) {
    console.log("#getGoogleDocsPreviewUrl  ", data.success);
    url = await b64toUrl(data.fileString);
  }
  return { url, base64File: data?.fileString };
};
export const getPreviewUrl = async (delta) => {
  let url = null;
  const data = await apiWrapWithErrorWithData(
    axios.post(`${constants.utilityHost}/utilities/common/get-pdf-in-base64`, {
      quillDelta: delta,
    })
  );
  if (data.success && data.fileString) {
    url = await b64toUrl(data.fileString);
  }
  return { url, base64File: data?.fileString };
};

export const getPreviewUrlofFile = ({key})=>
  axios.post(`${constants.utilityHost}/agreements/previewurl`, 
    {
      key: key
    },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementSignStatusApi = ({ agreementId }) =>
  axios.get(`${constants.utilityHost}/agreements/sign-details/${agreementId}`, {
    headers: headersProvider(),
  });
export const getAgreementAuditLog = async ({ agreementId }) =>
  await axios.get(
    `${constants.utilityHost}/agreement/AuditLogs/${agreementId}`,
    {
      headers: headersProvider(),
    }
  );

export const getRelatedAgreement = async ({ agreementId }) =>
  await axios.get(
    `${constants.utilityHost}/agreement/ByRelation/${agreementId}`,
    {
      headers: headersProvider(),
    }
  );

export const sendAgreementForSignature = ({
  signers,
  agreementId,
  ...params
}) =>
  axios.post(
    `${constants.utilityHost}/agreements/sign-initiate/${agreementId}`,
    {
      ...params,
      signers,
    },
    {
      headers: headersProvider(),
    }
  );

export const cancelAgreementSignature = ({ agreementId }) =>
  axios.post(
    `${constants.utilityHost}/agreements/sign-cancel/${agreementId}`,
    {},
    {
      headers: headersProvider(),
    }
  );

export const agreementAnalytics = () =>
  axios.get(`${constants.utilityHost}/agreements/analytics`, {
    headers: headersProvider(),
  });

export const getTotalAgreementsList = () =>
  axios.post(
    `${constants.utilityHost}/agreements/TotalList`,
    { page: 1, filterOptions: {}, sortByOptions: { createdAt: "desc" } },
    {
      headers: headersProvider(),
    }
  );
export const getAgreementsInReviewList = ({ page }) =>
  axios.post(
    `${constants.utilityHost}/agreements/CollaborationList`,
    { page: page, filterOptions: {}, sortByOptions: { createdAt: "desc" } },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementsInApprovalList = ({ page }) =>
  axios.post(
    `${constants.utilityHost}/agreements/ApprovalsList`,
    { page: page, filterOptions: {}, sortByOptions: { createdAt: "desc" } },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementsInSigningList = ({ page }) =>
  axios.post(
    `${constants.utilityHost}/agreements/SigningList`,
    { page: page, filterOptions: {}, sortByOptions: { createdAt: "desc" } },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementsInSignedList = ({ page }) =>
  axios.post(
    `${constants.utilityHost}/agreements/SignedList`,
    { page: page, filterOptions: {}, sortByOptions: { createdAt: "desc" } },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementTypesApi = () =>
  axios.get(`${constants.utilityHost}/agreements/get-distinct-types`, {
    headers: headersProvider(),
  });

export const getAgreementTitleApi = () =>
  axios.get(`${constants.utilityHost}/agreements/get-distinct-titles`, {
    headers: headersProvider(),
  });

export const getAgreementByStatus = () =>
  axios.get(`${constants.utilityHost}/agreements/get-agreement-by-status`, {
    headers: headersProvider(),
  });

export const createEditorField = ({ fieldName, agreementId }) =>
  axios.post(
    `${constants.utilityHost}/agreements/editor-field/${agreementId}`,
    { fieldName },
    {
      headers: headersProvider(),
    }
  );

  export const agreementLocation = ({ id }) =>
    axios.get(`${constants.utilityHost}/agreements/getlocation/${id}`, {
      headers: headersProvider(),
    });
    
export const deleteEditorField = ({ fieldName, agreementId }) =>
  axios.put(
    `${constants.utilityHost}/agreements/editor-field/${agreementId}`,
    { fieldName },
    {
      headers: headersProvider(),
    }
  );

export const sendForExternalReview = ({
  agreementId,
  email,
  messageForExternalReview,
}) =>
  // console.log("External Review Function called", email, agreementId);
  axios.post(
    `${constants.utilityHost}/agreement/TaskForExternalReview`,
    {
      agreementId: agreementId,
      emailAddress: email,
      emailContent: messageForExternalReview,
    },
    {
      headers: headersProvider(),
    }
  );

export const getEditorField = ({ agreementId }) =>
  axios.get(`${constants.utilityHost}/agreements/editor-field/${agreementId}`, {
    headers: headersProvider(),
  });

export const getRevisions = ({ documentEditorId }) =>
  axios.get(
    `${constants.utilityHost}/docs/fetchRevisions/${documentEditorId}`,
    {
      headers: headersProvider(),
    }
  );

export const fetchRevisionsHistoryLinks = (documentId, revisionId) =>
  axios.post(
    `${constants.utilityHost}/docs/fetchRevisionContent`,
    {
      documentId: documentId,
      revisionId: revisionId,
    },
    {
      headers: headersProvider(),
    }
  );

export const getUser = () =>
  axios.get(`${constants.utilityHost}/agreements/user/details`, {
    headers: headersProvider(),
  });

export const getRegisterUserApi = () =>
  axios.get(`${constants.utilityHost}/agreements/register-user`, {
    headers: headersProvider(),
  });

export const bulkAgreementUploadApi = ({ formData }) =>
  axios.post(
    `${constants.utilityHost}/agreements/bulk-agreement/create`,
    formData,
    {
      headers: {
        ...headersProvider(),
        "Content-Type": "multipart/form-data",
      },
    }
  );

  export const createBulkAgreements = ({formDataObject}) =>{
    return axios.post(
      `${constants.utilityHost}/agreements/bulk-agreement/createbulkAgreements`,
      formDataObject,
      {
        headers: {
          ...headersProvider(),
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

export const getAgreementsInReviewCsvDownload = ({
  page,
  sortByOptions,
  filterOptions,
}) =>
  axios.post(
    `${constants.utilityHost}/agreementinreview/csv`,
    {
      page,
      filterOptions,
      sortByOptions,
    },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementsInApproveCsvDownload = ({
  page,
  sortByOptions,
  filterOptions,
}) =>
  axios.post(
    `${constants.utilityHost}/agreementinapprove/csv`,
    {
      page,
      filterOptions,
      sortByOptions,
    },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementsSigningCsvDownload = ({
  page,
  sortByOptions,
  filterOptions,
}) =>
  axios.post(
    `${constants.utilityHost}/agreementinsigning/csv`,
    {
      page,
      filterOptions,
      sortByOptions,
    },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementsCsvDownload = ({
  page,
  sortByOptions,
  filterOptions,
}) =>
  axios.post(
    `${constants.utilityHost}/agreement/csv`,
    {
      page,
      filterOptions,
      sortByOptions,
    },
    {
      headers: headersProvider(),
    }
  );

export const getAgreementsDocsLockerCsvDownload = ({
  page,
  sortByOptions,
  filterOptions,
}) =>
  axios.post(
    `${constants.utilityHost}/agreements/docslockercasv`,
    {
      page,
      filterOptions,
      sortByOptions,
    },
    {
      headers: headersProvider(),
    }
  );
